<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <RewardDialog
          v-if="claimed && claimedItem"
          :callbackClose="exitRwd"
          :showDialog="true"
          :rewards="[
            {
              Item: {
                images: {
                  icon: JSON.parse(claimedItem.translatableImage)[$_getLocale()]
                    ? JSON.parse(claimedItem.translatableImage)[$_getLocale()]
                        .url
                    : JSON.parse(claimedItem.translatableImage)[
                        getSubjectLang(claimedItem)
                      ].url,
                },
              },
              quantity: 1,
            },
          ]"
        />
        <v-container class="app-body">
          <!-- BOC:[error] -->
          <ApiErrorDialog v-if="api.isError" :api="api" />
          <!-- EOC -->
          <div class="px-5">
            <div class="mb-3" v-if="otherBooks.length > 0">
              <v-card
                v-for="(item, i) in otherBooks"
                :key="i + 'all'"
                class="my-2 list-card"
                flat
              >
                <div class="d-flex flex-no-wrap">
                  <v-avatar class="ma-3" size="125" tile>
                    <v-img
                      v-if="item.translatableImage"
                      :src="
                        JSON.parse(item.translatableImage)[$_getLocale()]
                          ? JSON.parse(item.translatableImage)[$_getLocale()]
                              .url
                          : JSON.parse(item.translatableImage)[
                              getSubjectLang(item)
                            ].url
                      "
                    ></v-img>
                  </v-avatar>
                  <div
                    style="width: 100%"
                    class="d-flex flex-column justify-space-between"
                  >
                    <div class="text-h5 mx-2 pt-3">
                      <!-- {{
                        JSON.parse(item.translatableName)[$_getLocale()]
                          ? JSON.parse(item.translatableName)[$_getLocale()]
                          : JSON.parse(item.translatableName)[
                              getSubjectLang(item)
                            ]
                      }} -->
                      {{
                        JSON.parse(item.translatableName)[getSubjectLang(item)]
                      }}
                    </div>

                    <v-card-actions>
                      <v-chip small dark>{{ item.subject }}</v-chip>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="text-h6"
                        :color="item.type == 'premium' ? 'grey' : 'green'"
                        :class="
                          item.type == 'premium' ? '' : 'constant-tilt-shake'
                        "
                        dark
                        @click="claimBook(item)"
                        :loading="api.isLoading && selectedBook == item"
                      >
                        <v-icon v-if="item.type == 'premium'">
                          mdi-lock
                        </v-icon>
                        <div v-else>{{ $t("action.claim") }}</div>
                      </v-btn>
                    </v-card-actions>
                  </div>
                </div>
              </v-card>
            </div>

            <div v-if="myBooks.length > 0">
              <div class="text-h5">{{ $t("string.myBook") }}</div>
              <v-card
                v-for="(item, i) in myBooks"
                :key="i + 'own'"
                class="my-2 list-card"
                flat
              >
                <div class="d-flex flex-no-wrap">
                  <v-avatar class="ma-3" size="125" tile>
                    <v-img
                      v-if="item.translatableImage"
                      :src="
                        JSON.parse(item.translatableImage)[$_getLocale()]
                          ? JSON.parse(item.translatableImage)[$_getLocale()]
                              .url
                          : JSON.parse(item.translatableImage)[
                              getSubjectLang(item)
                            ].url
                      "
                    ></v-img>
                  </v-avatar>
                  <div
                    style="width: 100%"
                    class="d-flex flex-column justify-space-between"
                  >
                    <div class="text-h5 mx-2 pt-3">
                      {{
                        JSON.parse(item.translatableName)[$_getLocale()]
                          ? JSON.parse(item.translatableName)[$_getLocale()]
                          : JSON.parse(item.translatableName)[
                              getSubjectLang(item)
                            ]
                      }}
                    </div>

                    <div class="px-3" v-if="item.PlayerBook">
                      <v-progress-linear
                        color="green"
                        rounded
                        height="8"
                        :value="
                          (item._count.PlayerChapter / item._count.Chapter) *
                          100
                        "
                      ></v-progress-linear>
                    </div>

                    <v-card-actions class="flex-wrap">
                      <v-chip small dark class="my-2">{{
                        item.subject
                      }}</v-chip>
                      <v-spacer></v-spacer>
                      <v-btn
                        :class="i == 0 ? 'constant-tilt-shake' : ''"
                        class="text-h6"
                        color="blue"
                        dark
                        @click="read(item)"
                      >
                        {{ $t("string.read") }}
                      </v-btn>
                    </v-card-actions>
                  </div>
                </div>
              </v-card>
            </div>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
      
  <script>
import { mapState } from "vuex";
export default {
  components: {
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    RewardDialog: () =>
      import(
        /* webpackChunkName: "component-reward" */ "@/components/ABC/RewardBookDialog"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    selectedBook: null,
    claimed: false,
    otherBooks: [],
    myBooks: [],
    claimedItem: null,
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
  }),
  async created() {
    await this.fetchBooks();
  },
  mounted() {
    //
  },
  methods: {
    exitRwd() {
      this.claimed = false;
      this.read(this.claimedItem);
      // this.fetchBooks();
    },
    async fetchBooks() {
      this.api.url =
        this.$api.servers.event +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/event/book";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        [this.myBooks, this.otherBooks] = resp.Book.reduce(
          (acc, obj) => {
            if (obj["PlayerBook"].length > 0) {
              acc[0].push(obj);
            } else {
              acc[1].push(obj);
            }
            return acc;
          },
          [[], []]
        );

        if (this.otherBooks.length == 0) {
          this.read(this.myBooks[0]);
        }
      };
      this.api.params = {
        eventCode: this.$route.params.code,
        schoolYear: this.auth.Classroom
          ? this.auth.Classroom.schoolYear
          : this.auth.User.schoolYear,
      };
      await this.$api.fetch(this.api);
    },
    claimBook(book) {
      this.selectedBook = book;
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/abc/book/claim";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.claimedItem = book;
        this.claimed = true;
        this.api.isLoading = false;
      };
      this.api.params = {
        bookKey: book.key,
      };
      this.$api.fetch(this.api);
    },
    read(book) {
      this.$router.push({
        name: "PageEventABCBookChapters",
        params: { bookKey: book.key },
      });
    },
    getSubjectLang(item) {
      if (item.subject == "Mandarin") {
        return "zh";
      } else if (item.subject == "Malay") {
        return "ms";
      } else {
        return "en";
      }
    },
  },
};
</script>

<style scoped>
.constant-tilt-shake {
  animation: tilt-shaking 2s infinite;
}

.constant-tilt-shake:hover {
  animation: none;
}

@keyframes tilt-shaking {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  3% {
    transform: translate(2px, -2px) rotate(5deg);
  }
  6% {
    transform: translate(0, 0) rotate(0eg);
  }
  9% {
    transform: translate(-2px, -2px) rotate(-5deg);
  }
  12% {
    transform: translate(0, 0) rotate(0deg);
  }
  15% {
    transform: translate(2px, -2px) rotate(5deg);
  }
  18% {
    transform: translate(0, 0) rotate(0eg);
  }
  21% {
    transform: translate(-2px, -2px) rotate(-5deg);
  }
  24% {
    transform: translate(0, 0) rotate(0deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

.menu-button {
  padding: 0 5px;
  cursor: pointer;
  width: 33.33%;
  display: flex;
  justify-content: center;
}

.menu-item {
  cursor: pointer;
}

.menu-item:hover {
  scale: 1.1;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.list-card {
  padding: 5px;
  color: #785c3a;
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #fff9f1;
  overflow-x: hidden !important;
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}
</style>